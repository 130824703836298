import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import http from "../../utils/http";
import END_POINTS from "../../constants/endpoints";
import { toast } from "react-toastify";

export const resetLeaveTypeBalance = createAction("leaveTypeBalance");
export const resetAppliedLeave = createAction("appliedLeave");
export const resetLeaveState = createAction("updateLeave");
export const fetchRecentLeaves = createAsyncThunk(
  "leave/recent-leaves",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.RECENT_LEAVES}/${params.EmployeeId}?selectedMonth=${params.selectedMonth}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchLeaveApplicationV1 = createAsyncThunk(
  END_POINTS.GET_LEAVE_APPLICATION_V1,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.GET_LEAVE_APPLICATION_V1}`,
        {
          params: {
            filterfield: params?.filterfield,
            filterValue: params?.filterValue,
            page: params?.page,
            limit: params.limit,
            sort: params?.sort,
            order: params?.order,
            search: params?.search,
            status: params?.status,
            fromDate: params?.fromDate,
            toDate: params?.toDate,
          },
        }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const leaveUpdateStatusV1 = createAsyncThunk(
  END_POINTS.LEAVE_UPDATE_STATUS_V1,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.LEAVE_UPDATE_STATUS_V1}`, {
        leaveList: [...params?.leavelist],
        status: params?.status,
        management_comment: params?.management_comment,
        fromDate: params?.fromDate,
        toDate: params?.toDate,
        leaveType: params?.leaveType,
      });
      // toast.success(data.message)
      return data;
    } catch (error) {
      // toast.error(error.message)
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchLeaveAllocationDays = createAsyncThunk(
  "leave/leave-allotment-days",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LEAVE_ALLOTMENT_DAYS}/${params}`
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchOnleaveTodayData = createAsyncThunk(
  "leave/on-leave-today",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.ON_LEAVE_TODAY}`);

      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchOnLeaveTodayDataV1 = createAsyncThunk(
  END_POINTS.ON_LEAVE_TODAY_V1,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.ON_LEAVE_TODAY_V1}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const applyLeaveV1 = createAsyncThunk(
  END_POINTS.APPLY_LEAVE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.APPLY_LEAVE}`, {
        cc: [],
        description: "testing",
        employeeId: 10752,
        follow_via_email: 1,
        from_date: "2023-09-25",
        half_day: 0,
        half_day_info: null,
        leave_approver: "sharma.mayank@thinksys.com",
        leave_type: "Earned Leave",
        to_date: "2023-09-27",
        total_leave_days: 3,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const  fetchLeaveApprovers = createAsyncThunk(
  END_POINTS.LEAVE_APPROVE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LEAVE_APPROVE}/${params.EmployeeId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchLeaveType = createAsyncThunk(
  END_POINTS.LEAVE_TYPE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.LEAVE_TYPE}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchTotalAppliedLeave = createAsyncThunk(
  END_POINTS.APPLIED_LEAVE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.APPLIED_LEAVE}`, {
        params,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const postLeaveApplication = createAsyncThunk(
  END_POINTS.POST_LEAVE_APPLICATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(`${END_POINTS.POST_LEAVE_APPLICATION}`, {
        ...params,
      });
      if (data?.createdLeaveData?.leave_type === "Work From Home") {
        toast.success("Successfully Applied For Work From Home");
      } else if (data?.status) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const availableLeavesByEmployeeId = createAsyncThunk(
  END_POINTS.AVAILABLE_LEAVE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.AVAILABLE_LEAVE}/${params.EmployeeId}`,
        { params: { leaveType: params.leaveType } }
      );
      if (data?.state) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      error.response && toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const postLeaveAllocation = createAsyncThunk(
  END_POINTS.LEAVE_ALLOCATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.LEAVE_ALLOCATION}`,
        params
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

// export const fetchLeaveAllocationList = createAsyncThunk(
//   END_POINTS.LEAVE_ALLOCATION_LIST_v1,
//   async (params, { rejectWithValue }) => {
//     try {
//       const { data } = await http.get(
//         `${END_POINTS.LEAVE_ALLOCATION_LIST_v1}`,
//         params
//       );
//       // toast.success(data.message)
//       return data;
//     } catch (error) {
//       toast.error(error?.response?.data?.message);
//       return rejectWithValue(error.response ? error.response.data : error);
//     }
//   }
// );

export const fetchLeaveAllocationDataById = createAsyncThunk(
  END_POINTS.LEAVE_ALLOCATION_LIST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LEAVE_ALLOCATION_LIST}?limit=${
          params.limit ? params.limit : 15
        }&filter=${params.search ? params.search : ""}&page=${
          params.page ? params.page : 1
        }&sortBy=${params.sortBy ? params.sortBy : "creation"}&order=${
          params.order ? params.order : "DESC"
        }&selectedMonthStart=${
          params.selectedMonthStart ? params.selectedMonthStart : null
        }&isCalendar=${params.isCalendar ? params.isCalendar : false}`
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);
export const fetchAllLeaveAllocationV1 = createAsyncThunk(
  END_POINTS.LEAVE_ALLOCATION_REQUEST,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LEAVE_ALLOCATION_REQUEST}`,
        {
          params: {
            ...params,
          },
        }
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchBalanceDataById = createAsyncThunk(
  END_POINTS.LEAVE_BALANCE_BY_ID,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.LEAVE_BALANCE_BY_ID}/${params}`
      );
      return data.leaveBalance;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateAllocationRequestStatus = createAsyncThunk(
  END_POINTS.UPDATE_ALLOCATION_STATUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.patch(
        `${END_POINTS.UPDATE_ALLOCATION_STATUS}/${params}`
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateLeaveRequestStatus = createAsyncThunk(
  END_POINTS.UPDATE_LEAVE_STATUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPDATE_LEAVE_STATUS}`,
        params
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchLeaveAllocationDataV1 = createAsyncThunk(
  END_POINTS.REVIEW_LEAVE_ALLOCATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.REVIEW_LEAVE_ALLOCATION}/${params.employeeId}`,
        {
          params: {
            ...params,
          },
        }
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchAttendanceRegularizationDataV1 = createAsyncThunk(
  END_POINTS.ATTENDANCE_REGULARIZATION_V1,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(
        `${END_POINTS.ATTENDANCE_REGULARIZATION_V1}`,
        {
          params: {
            fromDate: params?.fromDate,
            toDate: params?.toDate,
            search: params?.search,
            status: params?.status,
            page: params?.page,
            limit: params.limit,
            order: params?.order,
            sort: params?.sort
          },
        }
      );
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const fetchMonthlyRegularizationData = createAsyncThunk(
  END_POINTS.MONTHLY_REGULARIZATION,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`${END_POINTS.MONTHLY_REGULARIZATION}`, {
        params,
      });
      return data.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateRegularizationStatus = createAsyncThunk(
  END_POINTS.UPDATE_REGULARIZATION_STATUS,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPDATE_REGULARIZATION_STATUS}`,
        params
      );
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);

export const updateRegulariedAttendance = createAsyncThunk(
  END_POINTS.UPDATE_REGULARIZED_ATTENDANCE,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await http.post(
        `${END_POINTS.UPDATE_REGULARIZED_ATTENDANCE}`,
        params
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error);
    }
  }
);



const initialState = {
  recentLeaves: { status: false, recentLeaves: [], isFetching: false },
  leaveAllocationDays: [],
  onLeaveTodayData: [],
  onLeaveTodayDataV1: {
    onLeaveToday: null,
    isFetching: true,
  },
  userLeaveApprover: {
    isFetching: false,
    error: null,
    approvers: [],
  },
  userRequestedAllocationList: {
    isFetching: false,
    error: null,
    allocationList: [],
  },
  leaveType: {
    isFetching: false,
    error: null,
    types: [],
  },
  appliedLeave: {
    isFetching: false,
    error: null,
    actualDaysApplied: 0,
  },
  leaveTypeBalance: {
    totalLeave: 0.0,
    leaveOpen: 0.0,
    leaveAvailable: 0.0,
    isFetching: false,
  },
  leaveAllocationById: {
    isFetching: false,
    leaveAllocationList: {},
  },
  leaveAllocationRes: {
    isFetching: false,
  },
  leaveAllocationResUpdate: {
    isFetching: false,
  },
  leaveStatusResUpdate: {
    isFetching: false,
  },
  leaveBalanceData: {
    isFetching: false,
    leaveBalance: [],
  },
  leaveApplicationDataV1: {
    isFetching: false,
    leaveData: [],
  },
  leaveAllocationDataV1: {
    isFetching: false,
    allocationData: [],
  },
  leaveUpdateStatusDataV1: {
    isFetching: false,
    responseData: undefined,
  },
  attendanceRegularizationV1: {
    isFetching: false,
    regularizationDataV1: [],
  },
  monthlyAttendanceRegularization: {
    isFetching: false,
    monthlyRegularizationData: [],
  },
  // attendanceRegularizationV1: {
  //   isFetching: false,
  //   regularizationDataV1: [],
  // },
  // attendanceRegularizationV1: {
  //   isFetching: false,
  //   regularizationDataV1: [],
  // },
  leaveAllocationRequest: {
    isFetching: false,
    allocationData: [],
  },
  // attendanceRegularizationV1: {
  //   isFetching: false,
  //   regularizationDataV1: [],
  // },
  loading: false,
  error: null,
  success: false,
};

export const leaveSlice = createSlice({
  name: "leaves",
  initialState,
  reducers: [],
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentLeaves.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.recentLeaves = {
          status: false,
          recentLeaves: [],
          isFetching: true,
        };
      })
      .addCase(fetchRecentLeaves.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.recentLeaves = {
          status: true,
          recentLeaves: payload.recentLeaves,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(fetchRecentLeaves.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.recentLeaves = {
          status: false,
          recentLeaves: [],
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(fetchLeaveAllocationDays.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeaveAllocationDays.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.leaveAllocationDays = payload.leaveAllocationData;
        state.error = null;
      })
      .addCase(fetchLeaveAllocationDays.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveAllocationDays = [];
        state.success = false;
      })
      .addCase(fetchOnleaveTodayData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOnleaveTodayData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.onLeaveTodayData = payload.onLeaveTodayData;
        state.error = null;
      })
      .addCase(fetchOnleaveTodayData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.onLeaveTodayData = [];
        state.success = false;
      })

      //FETCH ALLOCATION REQUEST LIST
      // .addCase(fetchLeaveAllocationList.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(fetchLeaveAllocationList.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   state.success = true;
      //   state.userRequestedAllocationList = {
      //     status: true,
      //     allocationList: payload,
      //   };
      //   state.error = null;
      // })
      // .addCase(fetchLeaveAllocationList.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   state.error = payload;
      //   state.userRequestedAllocationList = {
      //     status: false,
      //     allocationList: [],
      //   };
      //   state.success = false;
      // })

      // FETCH USER LEAVE APPROVER
      .addCase(fetchLeaveApprovers.pending, (state) => {
        state.userLeaveApprover.isFetching = true;
      })
      .addCase(fetchLeaveApprovers.fulfilled, (state, { payload }) => {
        state.userLeaveApprover.isFetching = false;
        state.userLeaveApprover.approvers = payload?.data;
      })
      .addCase(fetchLeaveApprovers.rejected, (state, { payload }) => {
        state.userLeaveApprover.isFetching = false;
        state.userLeaveApprover.error = payload;
        state.userLeaveApprover.approvers = [];
      })

      // FETCH USER LEAVE TYPE
      .addCase(fetchLeaveType.pending, (state) => {
        state.leaveType.isFetching = true;
      })
      .addCase(fetchLeaveType.fulfilled, (state, { payload }) => {
        state.leaveType.isFetching = false;
        state.leaveType.types = payload?.data?.rows?.map((value) => {
          return { ...value, [value.leave_type_name]: value.max_days_allowed };
        });
      })
      .addCase(fetchLeaveType.rejected, (state, { payload }) => {
        state.leaveType.isFetching = false;
        state.leaveType.error = payload;
        state.leaveType.types = [];
      })

      // FETCH TOTAL APPLIED LEAVE
      .addCase(fetchTotalAppliedLeave.pending, (state) => {
        state.appliedLeave.isFetching = true;
      })
      .addCase(fetchTotalAppliedLeave.fulfilled, (state, { payload }) => {
        state.appliedLeave.isFetching = false;
        state.appliedLeave.actualDaysApplied =
          payload?.totalDaysApplied?.actualDaysApplied;
      })
      .addCase(fetchTotalAppliedLeave.rejected, (state, { payload }) => {
        state.appliedLeave.isFetching = false;
        state.appliedLeave.actualDaysApplied = 0;
      })

      // POST ALLOCATION
      .addCase(postLeaveAllocation.pending, (state) => {
        state.leaveAllocationRes.isFetching = true;
      })
      .addCase(postLeaveAllocation.fulfilled, (state, { payload }) => {
        state.leaveAllocationRes.isFetching = false;
      })
      .addCase(postLeaveAllocation.rejected, (state, { payload }) => {
        state.leaveAllocationRes.isFetching = false;
      })

      // POST DISMISS LEAVE
      .addCase(updateLeaveRequestStatus.pending, (state) => {
        state.leaveAllocationRes.isFetching = true;
      })
      .addCase(updateLeaveRequestStatus.fulfilled, (state, { payload }) => {
        state.leaveAllocationRes.isFetching = false;
      })
      .addCase(updateLeaveRequestStatus.rejected, (state, { payload }) => {
        state.leaveAllocationRes.isFetching = false;
      })

      // PATCH ALLOCATION
      .addCase(updateAllocationRequestStatus.pending, (state) => {
        state.leaveAllocationResUpdate.isFetching = true;
      })
      .addCase(
        updateAllocationRequestStatus.fulfilled,
        (state, { payload }) => {
          state.leaveAllocationResUpdate.isFetching = false;
        }
      )
      .addCase(updateAllocationRequestStatus.rejected, (state, { payload }) => {
        state.leaveAllocationResUpdate.isFetching = false;
      })

      // FETCH LEAVE BALANCE BY LEAVE TYPE
      .addCase(availableLeavesByEmployeeId.pending, (state) => {
        state.leaveTypeBalance.isFetching = true;
      })
      .addCase(availableLeavesByEmployeeId.fulfilled, (state, { payload }) => {
        state.leaveTypeBalance.isFetching = false;
        state.leaveTypeBalance.totalLeave = parseFloat(
          payload?.availableLeave?.total_leaves_allocated
        ).toFixed(2);
        state.leaveTypeBalance.leaveOpen = parseFloat(
          payload?.openLeaves?.Open_Leave_days
        ).toFixed(2);
        state.leaveTypeBalance.leaveAvailable = parseFloat(
          payload?.availableLeave?.current_leave_balance
        ).toFixed(2);
      })
      .addCase(availableLeavesByEmployeeId.rejected, (state, { payload }) => {
        state.leaveTypeBalance.isFetching = false;
        state.leaveTypeBalance.totalLeave = 0.0;
        state.leaveTypeBalance.leaveOpen = 0.0;
        state.leaveTypeBalance.leaveAvailable = 0.0;
      })

      // FETCH LEAVE ALLOCATION LIST
      .addCase(fetchLeaveAllocationDataById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.leaveAllocationById = {
          isFetching: false,
          leaveAllocationList: {},
        };
      })
      .addCase(fetchLeaveAllocationDataById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.leaveAllocationById = {
          isFetching: false,
          leaveAllocationList: payload,
        };
        state.error = null;
      })
      .addCase(fetchLeaveAllocationDataById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveAllocationById = {
          isFetching: false,
          leaveAllocationList: {},
        };
        state.success = false;
      })

      // FETCH LEAVE BALANCE DATA
      .addCase(fetchBalanceDataById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.leaveBalanceData = {
          isFetching: true,
          leaveBalance: [],
        };
      })
      .addCase(fetchBalanceDataById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.leaveBalanceData = {
          isFetching: false,
          leaveBalance: payload,
        };
        state.error = null;
      })
      .addCase(fetchBalanceDataById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveBalanceData = {
          isFetching: false,
          leaveBalance: [],
        };
        state.success = false;
      })
      .addCase(fetchLeaveApplicationV1.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.leaveApplicationDataV1 = {
          status: false,
          leaveData: [],
          isFetching: true,
        };
      })
      .addCase(fetchLeaveApplicationV1.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.leaveApplicationDataV1 = {
          status: true,
          leaveData: payload,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(fetchLeaveApplicationV1.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveApplicationDataV1 = {
          status: false,
          leaveData: [],
          isFetching: false,
        };
        state.success = false;
      })

      .addCase(leaveUpdateStatusV1.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.leaveUpdateStatusDataV1 = {
          status: false,
          responseData: undefined,
          isFetching: true,
        };
      })
      .addCase(leaveUpdateStatusV1.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.leaveUpdateStatusDataV1 = {
          status: true,
          responseData: payload,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(leaveUpdateStatusV1.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveUpdateStatusDataV1 = {
          status: false,
          responseData: payload,
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(fetchLeaveAllocationDataV1.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.leaveAllocationDataV1 = {
          status: false,
          allocationData: [],
          isFetching: true,
        };
      })
      .addCase(fetchLeaveAllocationDataV1.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.leaveAllocationDataV1 = {
          status: true,
          allocationData: payload,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(fetchLeaveAllocationDataV1.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveAllocationDataV1 = {
          status: false,
          allocationData: [],
          isFetching: false,
        };
        state.success = false;
      })
      .addCase(fetchAllLeaveAllocationV1.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.leaveAllocationRequest = {
          status: false,
          allocationData: [],
          isFetching: true,
        };
      })
      .addCase(fetchAllLeaveAllocationV1.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.leaveAllocationRequest = {
          status: true,
          allocationData: payload,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(fetchAllLeaveAllocationV1.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.leaveAllocationRequest = {
          status: false,
          allocationData: [],
          isFetching: false,
        };
        state.success = false;
      })

      // Reset State
      .addCase(resetLeaveTypeBalance, (state) => {
        state.leaveTypeBalance.isFetching = false;
        state.leaveTypeBalance.totalLeave = 0.0;
        state.leaveTypeBalance.leaveOpen = 0.0;
        state.leaveTypeBalance.leaveAvailable = 0.0;
      })
      .addCase(resetAppliedLeave, (state) => {
        state.appliedLeave.actualDaysApplied = 0;
      })
      .addCase(resetLeaveState, (state) => {
        state.leaveUpdateStatusDataV1 = {
          responseData: undefined,
          isFetching: false,
        };
      })
      .addCase(fetchAttendanceRegularizationDataV1.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.attendanceRegularizationV1 = {
          status: false,
          regularizationDataV1: [],
          isFetching: true,
        };
      })
      .addCase(
        fetchAttendanceRegularizationDataV1.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.attendanceRegularizationV1 = {
            status: true,
            regularizationDataV1: payload,
            isFetching: false,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchAttendanceRegularizationDataV1.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.attendanceRegularizationV1 = {
            status: false,
            regularizationDataV1: [],
            isFetching: false,
          };
          state.success = false;
        }
      )
      .addCase(fetchMonthlyRegularizationData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.monthlyAttendanceRegularization = {
          status: false,
          monthlyRegularizationData: [],
          isFetching: true,
        };
      })
      .addCase(
        fetchMonthlyRegularizationData.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.success = true;
          state.monthlyAttendanceRegularization = {
            status: true,
            monthlyRegularizationData: payload,
            isFetching: false,
          };
          state.error = null;
        }
      )
      .addCase(
        fetchMonthlyRegularizationData.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.monthlyAttendanceRegularization = {
            status: false,
            monthlyRegularizationData: [],
            isFetching: false,
          };
          state.success = false;
        }
      )
      .addCase(updateRegularizationStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateRegularizationData = {
          isFetching: true,
          response: null,
        };
      })
      .addCase(updateRegularizationStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.updateRegularizationData = {
          isFetching: false,
          response: payload,
        };
        state.error = null;
      })
      .addCase(updateRegularizationStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.updateRegularizationData = {
          isFetching: false,
          response: null,
        };
        state.success = false;
      })
      .addCase(fetchOnLeaveTodayDataV1.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.onLeaveTodayDataV1 = {
          onLeaveToday: null,
          isFetching: true,
        };
      })
      .addCase(fetchOnLeaveTodayDataV1.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.onLeaveTodayDataV1 = {
          onLeaveToday: payload,
          isFetching: false,
        };
        state.error = null;
      })
      .addCase(fetchOnLeaveTodayDataV1.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.onLeaveTodayDataV1 = {
          onLeaveToday: null,
          isFetching: false,
        };
        state.success = false;
      });
  },
});

export default leaveSlice.reducer;
