import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";

const LeaveConfirmationDialog = ({
  confirmStatus,
  setConfirmStatus,
  setSelectedEmp,
  handleUpdate,
  status,
  setSelectedStatus,
}) => {
  const updateIsFetching = useSelector(
    (state) => state?.leave?.leaveUpdateStatusDataV1?.isFetching
  );
  return (
    <>
      <Dialog
        open={confirmStatus}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        className="employee_form_dialog relative"
      >
        {updateIsFetching && <Loader />}
        <DialogTitle className="add_employee_main_heading">
          <div className="commentsHeader">
            <div>Leave Confirmation</div>
            {/* <FontAwesomeIcon icon={faXmark} className='closeIcon' onClick={()=>setConfirmStatus(false)} /> */}
          </div>
        </DialogTitle>
        <DialogContent className="card-content-employee-form">
          <div className="leaveComments bg-white ">
            Are you sure you want to{" "}
            {status === "Approved" ? "approve" : "reject"} this leave
            application.
          </div>
        </DialogContent>
        <DialogActions className="employee_form_button_div">
          <button
            type="button"
            onClick={() => {
              setConfirmStatus(false);
              setSelectedStatus(null);
              setSelectedEmp([]);
            }}
            className="btn cancelBtnOutline"
          >
            Cancel
          </button>
          <button
            type="submit"
            // disabled={!isDirty}
            className="btn primaryBtnOutline"
            onClick={() => {
              handleUpdate(status);
            }}
          >
            Save
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeaveConfirmationDialog;
